<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="권한"
          tableId="riskHazrd01"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn label="초기화" icon="autorenew" @btnClicked="reset" />
                <c-btn v-if="editable" label="저장" icon="save" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-select
                  :editable="editable"
                  :comboItems="classItems"
                  :required="true"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="riskHazardClassCd"
                  label="분류"
                  v-model="riskHazard.riskHazardClassCd"
                ></c-select>
              </div>
              <div class="col-6">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="riskHazard.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="유해위험요인"
                  name="riskHazardNm"
                  v-model="riskHazard.riskHazardNm">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="관련근거(법적기준)"
                  name="col1"
                  v-model="riskHazard.col1">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'auth-group',
  data() {
    return {
      searchParam: {
        plantCd: null,
        keyword: '',
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'authGroupCd',
            field: 'authGroupCd',
            label: '권한코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'authGroupNm',
            field: 'authGroupNm',
            label: '권한명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      riskHazard: {
        riskHazardClassCd: null,
        riskHazardNm: '',
        col1: '',
        useFlag: 'Y'
      },
      editable: true,
      classItems: [], // 변경종류
      useFlagItems: [],
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.classItems = [
        { code: '1', codeName: this.$language('기계적(설비) 요인') },
        { code: '2', codeName: this.$language('생물학적 요인') },
        { code: '3', codeName: this.$language('전기적 요인') },
        { code: '4', codeName: this.$language('작업특성 요인') },
        { code: '5', codeName: this.$language('화학(물질)적 요인') },
        { code: '6', codeName: this.$language('작업환경 요인') },
        { code: '7', codeName: this.$language('관리적 요인') },
      ]
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          authGroupCd: 'AUTH001',
          authGroupNm: '일반사용자권한',
        },
        {
          authGroupCd: 'AUTH002',
          authGroupNm: '보건관리자',
        },
      ]
    },
    rowClick(row) {
      this.riskHazard = row
    },
    reset() {
      Object.assign(this.$data.riskHazard, this.$options.data().riskHazard);
    }
  }
};
</script>
